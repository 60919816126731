import Layout from "../layout/layout"
import SEO from "../components/seo"
import React, { useEffect } from "react"
import OurTeamOnJourney from "../components/OurTeamOnJourney"
import OurTeamList from "../components/OurTeamList"
import ContactInformation from "../components/ContactInformation"
import { AnalyticsEventCategories, emitAnalyticsEvent } from "../analytics"

const OurTeam = () => {
    useEffect(() => {
        emitAnalyticsEvent("Page view", AnalyticsEventCategories.TEAM)
    }, [])

    return (
        <Layout>
            <SEO title="Our team" />
            <OurTeamOnJourney />
            <OurTeamList />
            <ContactInformation />
        </Layout>
    )
}

export default OurTeam
