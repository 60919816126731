import React from "react"
import "./styles.scss"

import { useStaticQuery, graphql } from "gatsby"

function AddressMap(props) {
    const { file } = useStaticQuery(graphql`
        query getMap {
            file(sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "map" }) {
                publicURL
            }
        }
    `)
    return <img src={file.publicURL} alt="" />
}

export default function ContactInformation(props) {
    return (
        <div className={"contact-information"}>
            <div className="contact-information__item">
                <div className="contacts-info-box">
                    <div className="contacts-info-box__title">
                        <span>Contact info</span>
                    </div>

                    <p className="contacts-info-meta-text uppercase">STAY IN TOUCH</p>

                    <div className="address-box-container">
                        <ul className="address-box">
                            <li className="address-box__text">
                                <span>HQ Site</span>
                            </li>
                            <li className="address-box__text">
                                <span>Ze’ev Jabotinsky St 2,</span>
                            </li>
                            <li className="address-box__text">
                                <span>Ramat gan,</span>
                            </li>
                            <li className="address-box__text">
                                <span>Israel Atrium Building Floor 18</span>
                            </li>
                        </ul>
                        <ul className="address-box">
                            <li className="address-box__text">
                                <span>Kiev Site</span>
                            </li>
                            <li className="address-box__text">
                                <span>25B, P. Sagaydachnogo str,</span>
                            </li>
                            <li className="address-box__text">
                                <span> 5th floors, 4th,</span>
                            </li>
                            <li className="address-box__text">
                                <span>Kyiv, Ukraine, 04070</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="contact-information__item">
                <div className="map-container">
                    <a href="https://maps.google.com/?q=32.082861,34.800391" target={"_blank"}>
                        <AddressMap />
                    </a>
                </div>
            </div>
        </div>
    )
}
