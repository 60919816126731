import React from "react"
import "./styles.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function OurTeamOnJourney(props) {
    const { file } = useStaticQuery(graphql`
        query getTopBackground {
            file(
                sourceInstanceName: { eq: "images" }
                relativeDirectory: { eq: "about_us" }
                name: { eq: "top-bg@3x" }
            ) {
                publicURL
            }
        }
    `)

    return (
        <div className={"journey-container"}>
            <div className="journey-box">
                <div className={"journey-wrapper"}>
                    <h3>We’re on a journey.</h3>
                    <p className={"journey-description"}>
                        We develop data enriched communications tools to help you increase productivity, but more
                        importantly, generate more business.
                        <br />
                        Be dazzled when your dialer becomes a CRM, your calendar a source of business intelligence, and
                        all of it, linked directly to your email. When we’re not building apps and enriching your
                        business data, we can be found bike riding across all sorts of terrain!
                        <br />
                        But don’t just take our word for it.
                    </p>
                </div>
                <div className={"wrapper-journey-box"}>
                    <img src={file.publicURL} alt="" />
                </div>
            </div>
        </div>
    )
}
