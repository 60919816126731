import React from "react"
import "./styles.scss"
import { useStaticQuery, graphql } from "gatsby"

import GatsbyImage from "gatsby-image"

function Staff({ name, image_name, position, imagesStore }) {
    const avatar = imagesStore.edges.reduce((avatarPublicURL, image) => {
        if (Object.keys(avatarPublicURL).length === 0 && image.node.name === image_name) {
            avatarPublicURL = image.node.childImageSharp.fluid
        }
        return avatarPublicURL
    }, {})

    return (
        <div className={"staff-container"}>
            <div className={"staff-container__avatar-wrapper"}>
                <GatsbyImage fluid={avatar} />
            </div>
            <p className={"staff-container__name"}>{name}</p>
            <p className={"staff-container__position"}>{position}</p>
        </div>
    )
}

export default function OurTeam() {
    const { allTeam, allTeamImagesStore } = useStaticQuery(graphql`
        query teamListQuery {
            allTeam(sort: { fields: data___order_in_list, order: ASC }) {
                edges {
                    node {
                        data {
                            name
                            image_name
                            id
                            position
                        }
                    }
                }
            }
            allTeamImagesStore: allFile(filter: { relativeDirectory: { eq: "team_images" } }) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid {
                                aspectRatio
                                srcWebp
                                srcSetWebp
                                originalName
                                src
                                srcSet
                            }
                        }
                    }
                }
            }
        }
    `)
    return (
        <div className={"our-team-wrapper"}>
            <div className={"our-team-wrapper__header"}>
                <p className={"text-center"}>Our team</p>
            </div>

            <div className={"our-team-wrapper__body"}>
                {allTeam.edges.map((staff) => {
                    return <Staff {...staff.node.data} imagesStore={allTeamImagesStore} key={staff.id} />
                })}
            </div>
        </div>
    )
}
